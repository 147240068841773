/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCirclePlus } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import NavB from '../../../Nav/NavB';
import hero from '../../assets/hero.png';
import styles from './Home.module.scss';
import css from '../Stock/Stock.module.scss';
import RenderForm from '../../components/RenderForm';
import { getHarvestStock } from '../../../redux/reducer/harvestAction';
import { getFarmStock } from '../../../redux/reducer/farmAction';
import Loader from '../../../PalmSchool/components/Loader';
import { openForm } from '../../../redux/features/navigationSlice';

const PalmTrack = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { isOpenForm } = useSelector((state) => state.navigation);
  const { loading, harvested } = useSelector((state) => state.harvest);
  const { farms } = useSelector((state) => state.farm);

  const store = [...farms, ...harvested];

  useEffect(() => {
    dispatch(getHarvestStock());
    dispatch(getFarmStock());
  }, [dispatch]);

  const handleOpenForm = (e) => {
    e.preventDefault();
    dispatch(openForm());
  };

  return (
    <>
      <NavB palmtrack palmschool={false} palmvest={false} palmstore={false} />
      <div className={styles.hero}>
        <img src={hero} alt="hero" className={styles.img} />
        <p className={styles.heroText}>Take stock of your farm produce with PALMTRACK</p>
        {!isOpenForm && (
          <section className={styles.heroStore}>
            <article className={styles.heroStoreItem}>
              <p className={styles.text}>{((store && store.length) === 0) ? 'Your store is empty' : `You have ${store ? store.length : 0} items`}</p>
              <button type="button" className={styles.action} onClick={handleOpenForm}>
                <svg width="0" height="0">
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#7ec670', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: '#dcc15f', stopOpacity: 1 }} />
                  </linearGradient>
                </svg>
                <FaCirclePlus className={styles.icon} style={{ fill: 'url(#grad1)' }} />
                Add Product
              </button>
            </article>
          </section>
        )}
        {(store && !isOpenForm) && (
          <section className={css.store}>
            {store.map((stock, index) => (
              <motion.article
                key={stock._id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transit={(index + 1) * 0.2}
                className={css.storeCard}
              >
                <img
                  src={stock.productImage}
                  alt={stock.productImage}
                  className={css.img}
                />
                <div className={css.productInfo}>
                  {stock.cropPlanted && <h3 className={css.name}>{stock.cropPlanted}</h3>}
                  {stock.productName && <h3 className={css.name}>{stock.productName}</h3>}
                  <p className={css.unitPrice}>
                    <span>N</span>
                    {stock.pricePerUnit.toLocaleString()}
                  </p>
                  {stock.expectedYield && (
                    <p className={css.qty}>
                      <span>Qty: </span>
                      {stock.expectedYield}
                    </p>
                  )}
                  {stock.availableQty && (
                    <p className={css.qty}>
                      <span>Qty: </span>
                      {stock.availableQty}
                    </p>
                  )}
                  <p className={css.location}>
                    <span>Location: </span>
                    {stock.address}
                  </p>
                </div>
              </motion.article>
            ))}
          </section>
        )}
        {isOpenForm && (<RenderForm stock={false} />)}
      </div>
      {loading && <Loader loading={loading} />}
    </>
  );
};

export default PalmTrack;
