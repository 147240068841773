/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdContentCopy, MdRemoveShoppingCart } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';
import { useFormik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import { RiDeleteBinLine, RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import states from '../../PalmTrack/assets/states.json';
import styles from './css/Cart.module.scss';
import { updateQuantity, removeProduct, clearCart } from '../../redux/features/cartSlice';
import { saveAddress } from '../../redux/features/orderSlice';
import Alert from '../components/Underdev';
import Loader from '../../PalmSchool/components/Loader';
import { createOrderReq } from '../../redux/reducer/orderAction';
import Success from '../components/Success';

const options = {
  theme: 'light',
  position: 'top-right',
  autoClose: 2000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const animateBook = {
  isHidden: { opacity: 0, x: '-100%' },
  isVisible: (transit) => ({
    opacity: 1,
    x: 0,
    transition: { delay: transit },
  }),
};

const validationSchema = Yup.object().shape({
  address: Yup.string().required('This address is required'),
  address2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('Select state'),
  phone: Yup.string().required('Phone number is required'),
  lga: Yup.string().required('Select a Local Government Area'),
  delivery: Yup.mixed().required('Method of delivery is required'),
  payment: Yup.mixed().required('Payment option is required'),
});

const Cart = () => {
  const dispatch = useDispatch();
  const [arrowToggle, setArrowToggle] = useState({ state: false, lga: false });
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [needProof, setNeedProof] = useState(false);
  const {
    products, total, type,
  } = useSelector((state) => state.cart);
  const isSuccess = useSelector((state) => state.orders.cartSuccess);
  const TYPE = useSelector((state) => state.orders.type);
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const address = useSelector((state) => state.orders.shippingInfo);
  const address2 = useSelector((state) => state.orders.address);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (address && Object.keys(address).length > 0) {
      setIsNewAddress(false);
    } else {
      setIsNewAddress(true);
    }
  }, []);

  const removeProductHandler = async (productId) => {
    try {
      const res = dispatch(removeProduct({ productId }));
      setLoading(false);
      if (res.payload && isSuccess) {
        setIsPopupVisible(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess && TYPE === 'create') {
      dispatch(clearCart());
    }
  }, [isSuccess, TYPE]);

  const formik = useFormik({
    initialValues: {
      address: '',
      address2: '',
      phone: '',
      city: '',
      state: '',
      lga: '',
      delivery: 'pickup',
      payment: 'banktransfer',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!values) {
        toast.error('Please fill in the required fields', options);
        return;
      }
      setIsNewAddress(false);
      const formData = values;
      dispatch(saveAddress({
        address: formData.address,
        address2: formData.address2,
        phone: formData.phone,
        city: formData.city,
        state: formData.state,
        lga: formData.lga,
      }));
    },
  });

  const checkFormOption = () => {
    if (formik.values.delivery === 'pickup') {
      return false;
    } if (formik.values.delivery === 'home') {
      if (!address || isNewAddress) {
        return true;
      }
      return false;
    }
    return false;
  };

  const [formVisible, setFormVisible] = useState(checkFormOption());

  useEffect(() => {
    setFormVisible(checkFormOption());
    if (formik.values.delivery === 'pickup') {
      setIsNewAddress(false);
    }
  }, [formik.values.delivery, isNewAddress]);

  const handleQuantityChange = (productId, type) => {
    const cart = products.find((item) => item._id === productId);
    if (!cart) return;

    let newQuantity = cart.quantity;
    if (type === 'sub') {
      if (cart.quantity === 1) {
        toast.error('Quantity cannot be less than 1', options);
        return;
      }
      newQuantity = Math.max(1, cart.quantity - 1);
    } else {
      newQuantity += 1;
    }
    dispatch(updateQuantity({
      productId, newQuantity,
    }));
  };

  const handleArrowToggle = (field) => {
    setArrowToggle((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formik.values.delivery === 'home' && !address) {
      setLoading(false);
      toast.error('Please provide your contact address', options);
      return;
    }

    if (!formik.values.delivery) {
      setLoading(false);
      toast.error('Please provide your delivery address', options);
      return;
    }

    if (!formik.values.payment) {
      setLoading(false);
      toast.error('Please provide your payment option', options);
      return;
    }

    const { payment, delivery } = formik.values;

    if (payment === 'banktransfer') {
      setNeedProof(true);
    } else {
      setNeedProof(false);
    }

    try {
      const res = await createOrderReq(dispatch, products, address, total, payment, delivery);
      setLoading(false);
      if (res.payload && isSuccess) {
        setIsPopupVisible(true);
      } else {
        setIsPopupVisible(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <section className={styles.stockContainer}>
      <h3 className={styles.title}>My Cart</h3>
      <article className={styles.cartsDiv}>
        <AnimatePresence>
          {products.length > 0 && products.map((item, index) => (
            <motion.article
              key={item._id}
              variants={animateBook}
              initial="isHidden"
              animate="isVisible"
              exit="isHidden"
              layoutId={index}
              transit={(index + 1) * 0.2}
              className={styles.storeCard}
            >
              <div className={styles.group}>
                <div className={styles.imgDiv}>
                  <img src={item.productImage} alt={item.title} className={styles.img} />
                </div>
                <div className={styles.productInfo}>
                  <div className={styles.textDiv}>
                    <h4 className={styles.name}>{item.title}</h4>
                    <p className={styles.text}>
                      <span>₦</span>
                      {item.price.toLocaleString()}
                    </p>
                  </div>
                  <h4 className={styles.price}>
                    ₦
                    {(item.price * item.quantity).toLocaleString()}
                  </h4>
                </div>
              </div>
              <div className={styles.actionDiv}>
                <RiDeleteBinLine
                  className={styles.del}
                  onClick={(e) => {
                    e.preventDefault();
                    removeProductHandler(item._id);
                    setIsPopupVisible(true);
                  }}
                />
                <motion.section className={styles.addUp}>
                  <p className={styles.quantity}>
                    <button type="button" className={styles.btnQ} onClick={() => handleQuantityChange(item._id, 'sub')}>-</button>
                    <span>{item.quantity}</span>
                    <button type="button" className={styles.btnQ} onClick={() => handleQuantityChange(item._id, 'add')}>+</button>
                  </p>
                </motion.section>
              </div>
            </motion.article>
          ))}
        </AnimatePresence>
      </article>
      {products.length > 0 ? (
        <>
          <article className={styles.delivery}>
            <section className={styles.delOption}>
              <h4 className={styles.heading}>Delivery Option</h4>
              <label htmlFor="home">
                <input
                  type="radio"
                  name="delivery"
                  checked={formik.values.delivery === 'home'}
                  onChange={formik.handleChange}
                  value="home"
                  className={styles.input}
                />
                Home Delivery
                <span className={styles.checkmark} />
              </label>

              {(formik.values.delivery === 'home') && (
              <motion.article
                key="address"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layout
                className={styles.info}
              >
                {(address || address2) && (
                  <div className={styles.addressDiv}>
                    <p>{address.address}</p>
                    <p>{address.address2}</p>
                    <p>{`${address.city}, ${address.lga}.`}</p>
                    <p>{`${address.state}, ${address.state}.`}</p>
                    <p>{address.phone}</p>
                  </div>
                )}
                <button type="button" onClick={(e) => { e.preventDefault(); setIsNewAddress(true); }} className={styles.continue}>
                  {isNewAddress ? 'Add Address' : 'Change Address'}
                </button>
              </motion.article>
              )}
              {formVisible && (
                <AnimatePresence>
                  <motion.form
                    key="newAddress"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    layout
                    className={styles.form}
                    onSubmit={formik.handleSubmit}
                  >
                    <div className={styles.inputDiv}>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="123 Ikot Ekpene Road"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                      />
                      {formik.touched.address && formik.errors.address ? (
                        <small className={styles.error}>
                          <span style={{ color: 'red' }}>* </span>
                          {formik.errors.address}
                        </small>
                      ) : null}
                    </div>
                    <div className={styles.inputDiv}>
                      <input
                        type="text"
                        id="address2"
                        name="address2"
                        placeholder="Behind LG Office, close to roundabout"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address2}
                      />
                      {formik.touched.address2 && formik.errors.address2 ? (
                        <small className={styles.error}>
                          <span style={{ color: 'red' }}>* </span>
                          {formik.errors.address2}
                        </small>
                      ) : null}
                    </div>
                    <div className={styles.formSplit}>
                      <div className={styles.inputDiv}>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          placeholder="+23490112233"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <small className={styles.error}>
                            <span style={{ color: 'red' }}>* </span>
                            {formik.errors.phone}
                          </small>
                        ) : null}
                      </div>
                      <div className={styles.inputDiv}>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          placeholder="Uyo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <small className={styles.error}>
                            <span style={{ color: 'red' }}>* </span>
                            {formik.errors.city}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className={styles.formSplit}>
                      <div className={styles.inputDiv}>
                        <select
                          name="state"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onClick={() => handleArrowToggle('state')}
                          value={formik.values.state}
                        >
                          <option value="" label="State" />
                          {states.map((state) => <option key={state.state} value={state.state} label={state.state} />)}
                        </select>
                        {!arrowToggle.state ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
                        {formik.touched.state && (formik.errors.state || formik.values.state === 'state') ? (
                          <small className={styles.error}>
                            <span style={{ color: 'red' }}>* </span>
                            {formik.errors.state}
                          </small>
                        ) : null}
                      </div>
                      <div className={styles.inputDiv}>
                        <select
                          name="lga"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onClick={() => handleArrowToggle('lga')}
                          value={formik.values.lga}
                        >
                          <option value="" label="LGA" />
                          {states.map((state) => (state.state === formik.values.state) && state.lgas.map((lga) => <option key={lga} value={lga} label={lga} />))}
                        </select>
                        {!arrowToggle.lga ? <RiArrowDownSFill className={styles.icon} /> : <RiArrowUpSFill className={styles.icon} />}
                        {formik.touched.lga && formik.errors.lga ? (
                          <small className={styles.error}>
                            <span style={{ color: 'red' }}>* </span>
                            {formik.errors.lga}
                          </small>
                        ) : null}
                      </div>
                    </div>
                    <button type="submit" className={styles.btn}>
                      Save
                    </button>
                  </motion.form>
                </AnimatePresence>
              )}
              <label htmlFor="pickup">
                <input type="radio" name="delivery" value="pickup" checked={formik.values.delivery === 'pickup'} onChange={formik.handleChange} className={styles.input} />
                Pick up from shop
                <span className={styles.checkmark} />
              </label>

              {formik.values.delivery === 'pickup' && (
                <motion.article
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className={styles.pickupAddress}
                >
                  <h4 className={styles.heading}>Pickup Address</h4>
                  <p className={styles.text}>No 1, Enene Afaha, Uyo, Akwa Ibom State</p>
                  <a href="tel:+23490112233" className={styles.text}>+23490112233</a>
                </motion.article>
              )}
            </section>
            <section className={styles.delOption}>
              <h4 className={styles.heading}>Payment Option</h4>
              <label htmlFor="cash">
                <input type="radio" name="payment" checked={formik.values.payment === 'cash'} onChange={formik.handleChange} value="cash" className={styles.input} />
                Pay on Delivery
                <span className={styles.checkmark} />
              </label>
              <label htmlFor="banktransfer">
                <input type="radio" name="payment" checked={formik.values.payment === 'banktransfer'} onChange={formik.handleChange} value="banktransfer" className={styles.input} />
                Pay now
                <span className={styles.checkmark} />
              </label>
              {formik.values.payment === 'banktransfer' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={styles.paymentInfo}
              >
                <h4 className={styles.heading}>Payment Instruction</h4>
                <p className={styles.text}>Kindly make a transfer or bank deposit to the account details below:</p>
                <article className={styles.bankInfo}>
                  <h5 className={styles.heading}>Bank Details</h5>
                  <p className={styles.text}>
                    Account Number:
                    <span> 1026542976</span>
                  </p>
                  <div className={styles.paste}>
                    <CopyToClipboard text="1026542976" onCopy={handleCopy} className={styles.copy}>
                      {!isCopied ? <MdContentCopy className={styles.icon} /> : <GiCheckMark className={styles.icon} />}
                    </CopyToClipboard>
                    {isCopied && <span>Copied!</span>}
                  </div>
                  <p className={styles.text}>
                    Bank Name:
                    <span> UBA</span>
                  </p>
                  <p className={styles.text}>
                    Account Name:
                    <span> Josult Oil Limited</span>
                  </p>
                </article>
              </motion.div>
              )}
            </section>
          </article>
          <article className={styles.summary}>
            <p className={styles.text}>
              Subtotal:
              <span>{` ₦${products.reduce((acc, item) => acc + (item.price * item.quantity), 0).toLocaleString()}`}</span>
            </p>
            <p className={styles.text}>
              Delivery Fee:
              <span> ₦0</span>
            </p>
            <h3 className={styles.total}>
              Total:
              <span>{` ₦${products.reduce((acc, item) => acc + (item.price * item.quantity), 0).toLocaleString()}`}</span>
            </h3>
          </article>
          {formik.values.payment === 'cash' && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              type="button"
              className={styles.btnOut}
              onClick={handlePayment}
            >
              Checkout
            </motion.button>
          )}
          {formik.values.payment === 'banktransfer' && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              type="button"
              className={styles.btnOut}
              onClick={handlePayment}
            >
              I Have Paid
            </motion.button>
          )}
        </>
      ) : (
        <div className={styles.empty}>
          <MdRemoveShoppingCart className={styles.icon} />
          <p className={styles.text}>Your cart is empty</p>
        </div>
      )}
      {loading && <Loader loading={loading} />}
      {isPopupVisible && <Alert visible={isPopupVisible} text={type} onClose={() => setIsPopupVisible(false)} />}
      {(isSuccess && needProof) && <Success title="Order Successful" text="Kindly go to 'Order' to upload your proof of payment" link="Go to homepage" home="" />}
      {(isSuccess && !needProof) && <Success title="Order Successful" text="One of our top agents will contact you shortly" link="Go to homepage" home="" />}
    </section>
  );
};

export default Cart;
